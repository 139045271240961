<template>
    <div class="mainWrapper">


        <SupervisorMainSideBar v-if="windowWidth > mobileWidthThreshold" /> 

        <div v-else>

            <SupervisorMobileTopNav />

            <SupervisorMobileBottomNav />

        </div>





        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBarVue v-if="windowWidth > mobileWidthThreshold" />
            </div>


            <div class="innerContainer">

                <div class="theHeaderWrapper">
                    <div>Group Talks</div>


                </div>

                <div class="searchBarWrapper">
                    <input v-model="searchQuery" type="text" class="form-control theBar" placeholder="Search..." />
                </div>





                <div class="tableWrapper">




                    <router-link class="rowWrapper" v-for="(groupTalkPost, index) in filteredTalks" :key="index" 
                    
                    :to="{
                        name: 'SupViewGroupTalk',
                        params: { uuid: groupTalkPost.uuid }
                    }">

                        <div style="width: 90%;">

                            <div class="talkTitle">

                                {{ groupTalkPost.post ? groupTalkPost.post.title :
                                    'N/A' }}

                            </div>

                            <div class="bottomPart">

                                <div class="signedPart">
                                    Completed:

                                    <div v-if="groupTalkPost.is_acknowledged">

                                        <span class="badge rounded-pill bg-success">YES</span>

                                    </div>

                                    <div v-else>

                                        <span class="badge rounded-pill bg-danger">NO</span>

                                    </div>



                                </div>

                                <div class="datePart" v-if="groupTalkPost.is_acknowledged">
                                    Date: {{ groupTalkPost.date_acknowledged }}
                                </div>




                            </div>


                        </div>

                        <div  class="iconWrapper" style="width: 10%;">

                            <i class="bi bi-chevron-right" style="font-size: 20px; font-weight: bolder;"></i>

                        </div>

                    </router-link> 












                </div>



                <button v-if="displayedTalkCount < supervisorDashboardStore.groupTalkPosts.length"
                    class="btn btn-dark loadMoreBtn" @click="loadMoreTalks">Load more</button>


            </div>






        </div>





    </div>
</template> 

<script setup>

import { ref, onMounted, onUnmounted, computed } from 'vue';
import TopBarVue from "@/components/TopBar.vue";
import { useSupervisorDashboardStore } from '@/stores/SupervisorDashboardStore'; 

import SupervisorMainSideBar from '@/components/SupervisorMainSideBar.vue';
import SupervisorMobileTopNav from '@/components/SupervisorMobileTopNav.vue';
import SupervisorMobileBottomNav from '@/components/SupervisorMobileBottomNav.vue';


const supervisorDashboardStore = useSupervisorDashboardStore();

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed

const onResize = () => {
    windowWidth.value = window.innerWidth;
};


const searchQuery = ref(''); 

const displayedTalkCount = ref(35); // Start with displaying 5 teams
const incrementStep = 15; //



/* eslint-disable */ 
const filteredTalks = computed(() => {

    return supervisorDashboardStore.groupTalkPosts
        .filter(groupTalkPost => groupTalkPost.post && groupTalkPost.post.title.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedTalkCount.value);
});


const loadMoreTalks = () => {
    displayedTalkCount.value += incrementStep;
};


onMounted(() => {

    supervisorDashboardStore.fetchAllGroupTalksForThisSupervisor()

    window.addEventListener('resize', onResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);
});



</script>

<style  scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    color: initial;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
    
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}
</style>