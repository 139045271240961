<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <GroupWizardProgressBarVue />

                <div class="innerContainer">
                    <div class="theHeaderWrapper">

           
                        <!-- <div> {{ groupTalkStore.groupTalk.name }} - {{
                            groupTalkStore.groupTalk.team ? groupTalkStore.groupTalk.team.name : '' }}</div> -->

                        <div>
                            <i @click="groupTalkStore.exitWizard"
                            class="bi bi-x-lg" style="cursor: pointer;"></i>
                        </div>


                    </div>



                    <div class="wizardWrapper">


                        <div v-for="(wizardStep, index) in groupTalkStore.wizardSteps" :key="index">

                            <div v-if="wizardStep.step === groupTalkStore.currentStep">

                                <div v-if="wizardStep.step === 'message_goal_step'" class="messageGoalBox">

                                    <div class="wizardQuestion">Choose your goal</div>

                                    <div class="wizardOptionsWrapper">
                                        <div class="goalItem" v-for="(messageGoal, index) in groupTalkStore.messageGoals"
                                            :key="index" @click="groupTalkStore.setSelectedMessageGoal(messageGoal)" :class="{
                                                goalItemSelected:
                                                    groupTalkStore.post.postData.message_goal_id ===
                                                    messageGoal.id,
                                            }">
                                            <div>
                                                <i :class="messageGoal.icon"></i>
                                            </div>

                                            <div class="optionHeader">
                                                {{ messageGoal.name }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <div></div>

                                        <button class="button-31"
                                            @click="groupTalkStore.navigateToTalkCreationBasedOnMessageGoal">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'text_editor_step'" class="textEditorBoxWrapper">

                                    <div class="outerWrapper">

                                        <div class="textEditorBox">

                                            <div class="textEditorHeader">
                                                <div style="width: 50%">
                                                    <b style="float: left">Text Editor</b>
                                                </div>

                                                <div class="editorBtns">

                                                    <!-- <button class="button-13 aiBtn" role="button">
                                                        Create with AI
                                                    </button> -->


                                                    <button
                                                        @click="() => { groupTalkStore.openTextTalksLibraryModal(); groupTalkStore.fetchAllTextSafetyTalks(); }"
                                                        class="button-13 tempBtn">
                                                        Templates
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="titleBox">
                                                <input v-model="groupTalkStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>

                                            <div class="contentInputBody">

                                                <div class="textEditorWrapper">



                                                    <div v-if="editor">

                                                        <div class="btnBox">


                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleBold().run()"
                                                                :disabled="!editor.can().chain().focus().toggleBold().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('bold') }">

                                                                <i class="bi bi-type-bold"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleItalic().run()"
                                                                :disabled="!editor.can().chain().focus().toggleItalic().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('italic') }">

                                                                <i class="bi bi-type-italic"></i>

                                                            </div>


                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleUnderline().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('underline') }">

                                                                <i class="bi bi-type-underline"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setParagraph().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('paragraph') }">

                                                                <i class="bi bi-paragraph"></i>

                                                            </div>




                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 3 }) }">

                                                                <i class="bi bi-type-h3"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 4 }) }">

                                                                <i class="bi bi-type-h4"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 5 }) }">

                                                                <i class="bi bi-type-h5"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 6 }) }">

                                                                <i class="bi bi-type-h6"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('left').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'left' }) }">

                                                                <i class="bi bi-text-left"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('center').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'center' }) }">

                                                                <i class="bi bi-text-center"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('right').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'right' }) }">

                                                                <i class="bi bi-text-right"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleBulletList().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('bulletList') }">

                                                                <i class="bi bi-list-task" style="font-size: 20px;"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleOrderedList().run()"
                                                                :class="{ 'is-active': editor.isActive('orderedList') }">
                                                                <i class="bi bi-list-ol" style="font-size: 20px;"></i>
                                                            </div>



                                                            <div class="btnItem" @click="triggerFileInput">

                                                                <i class="bi bi-card-image"></i>

                                                                <input type="file" id="fileInput" hidden
                                                                    @change="handleFileChange" accept="image/*">




                                                            </div>




                                                        </div>

                                                    </div>



                                                    <EditorContent :editor="editor" />

                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    <div class="navBtnsWrapper">

                                        <button @click="groupTalkStore.goBackToMessageGoal" class="button-31" role="button"
                                            style="margin-left: 100px">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="groupTalkStore.goToScheduleMessageStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'video_step'" class="videoWrapper">

                                    <div class="videoOuterBox">

                                        <div class="videoBox">
                                            <div class="videoHeader">
                                                <div><b>Video</b></div>

                                                <div>

                                                    <button @click="groupTalkStore.openVideoEmbedModal" class="button-13"
                                                        style="margin-right: 10px">
                                                        Embed
                                                    </button>

                                                    <button
                                                        @click="() => { groupTalkStore.fetchAllVideoSafetyTalks(); groupTalkStore.openVideoLibraryModal(); }"
                                                        class="button-13">Library</button>
                                                </div>
                                            </div>

                                            <div class="videoTitleWrapper">
                                                <input v-model="groupTalkStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>


                                            <div class="videoContent">


                                                <div v-if="groupTalkStore.renderVideoEmbedCode === ''"
                                                    style="padding-bottom: 30px;">

                                                    No video added yet.

                                                </div>

                                                <div v-else>

                                                    <div v-html="groupTalkStore.renderVideoEmbedCode"
                                                        class="videoRenderedIframe"></div>

                                                </div>




                                            </div>
                                        </div>

                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button @click="groupTalkStore.goBackToMessageGoal" class="button-31" role="button"
                                            style="margin-left: 100px">
                                            Previous
                                        </button>

                                        <button @click="groupTalkStore.goToScheduleMessageStep" class="button-31"
                                            style="margin-right: 100px">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'pdf_step'" class="pdfWrapper">

                                    <div class="pdfOuterWrapper">

                                        <div class="pdfBox">

                                            <div class="pdfTopWrapper">

                                                <div class="pdfActionsWrapper">

                                                    <button
                                                        @click="() => { groupTalkStore.fetchPDFtalksFromLibrary(); groupTalkStore.openPDFLibraryModal(); }"
                                                        class="button-13" style="margin-right: 10px">
                                                        Library
                                                    </button>

                                                    <!-- <button 
                                                        @click="() => { groupTalkStore.openPDFLibraryModal(); groupTalkStore.fetchPDFtalksFromLibrary(); }"
                                                        class="button-13">Librarysss</button> -->

                                                    <form enctype="multipart/form-data"
                                                        @submit.prevent="groupTalkStore.savePDF">

                                                        <div class="theUploadBox">


                                                            <input class="form-control" type="file"
                                                                @change="groupTalkStore.pdfOnChange" accept=".pdf"
                                                                required />

                                                            <button type="submit" class="button-13"
                                                                style="margin-left: 5px">
                                                                Upload
                                                            </button>


                                                        </div>

                                                    </form>


                                                </div>
                                            </div>

                                            <div class="pdfTitleWrapper">
                                                <input v-model="groupTalkStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>


                                            <div v-if="groupTalkStore.isLoading">

                                                <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>

                                            </div>

                                            <div v-if="groupTalkStore.source1">

                                                <div class="deletePDFbox">

                                                    <div>
                                                        <b>Preview</b>
                                                    </div>

                                                    <div>
                                                        <i class="bi bi-trash" @click="groupTalkStore.openDeletePDFmodal()"
                                                            style="font-size: 17px; font-weight: bold; color: red; cursor: pointer;"></i>
                                                    </div>

                                                </div>

                                                <div class="pdfContent">


                                                    <vue-pdf-embed :source="groupTalkStore.source1" />

                                                </div>

                                            </div>





                                        </div>


                                    </div>

                                    <div class="navBtnsWrapper">

                                        <button @click="groupTalkStore.goBackToMessageGoal" class="button-31" role="button"
                                            style="margin-left: 100px">
                                            Previous
                                        </button>

                                        <button @click="groupTalkStore.goToScheduleMessageStep" class="button-31"
                                            style="margin-right: 100px">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'message_details_step'" class="messageDetailsBox">

                                    <div class="messageBoxOuterWrapper">

                                        <div class="messageBox">

                              

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Message</label>

                                                <textarea v-model="groupTalkStore.post.postData.message"
                                                    placeholder="Example: Good morning team, in an effort to prevent fall injuries, please review this ladder safety talk to ensure proper ladder usage on the construction site."
                                                    class="form-control" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Send Date</label>
                                                <input v-model="groupTalkStore.post.postData.send_date" type="date"
                                                    class="form-control" />
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Send Time</label>
                                                <input v-model="groupTalkStore.post.postData.send_time" type="time"
                                                    class="form-control" />
                                            </div>


                                            <div class="mb-3">
                                                <label class="form-label" style="float: left"><b>Supervisor</b></label>

                                                <select v-model="groupTalkStore.post.postData.supervisor_user_id" class="form-select custom-select" required>
                                                    <option value="" disabled>Select</option>

                                                    <option v-for="(supervisor, index) in groupTalkStore.supervisors" :key="index" :value="supervisor.id">
                                                    {{ supervisor.name }}
                                                    </option>

                                                </select>


                                            </div>


                                            <div class="mb-3">
                                                <label class="form-label" style="float: left"><b>Team</b></label>

                                                <select v-model="groupTalkStore.post.postData.team_id" class="form-select custom-select" required>
                                                    <option value="" disabled>Select a team</option>

                                                    <option v-for="(team, index) in groupTalkStore.teams" :key="index" :value="team.id">
                                                    {{ team.name }}
                                                    </option>

                                                </select>


                                            </div>



                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="groupTalkStore.navigateToTalkCreationBasedOnMessageGoal"> 
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="groupTalkStore.goToSaveOrPublishStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'save_or_publish_step'" class="saveOrPublishBox">
                                    <div class="wizardQuestion">
                                        Save Group Talk
                                    </div>

                                    <div class="wizardOptionsWrapper">

                                        <div v-for="(status, index) in groupTalkStore.statusArray" :key="index"
                                            class="goalItem" :class="{
                                                goalItemSelected: groupTalkStore.isPublishedSelected,
                                            }" @click="groupTalkStore.isPublishedSelected = true">
                                            <div class="optionHeader" style="font-size: 20px">
                                                {{ status.label }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">

                                        <button :disabled="groupTalkStore.isLoading"
                                            @click="groupTalkStore.goingBackFromSaveAsDraftOrPublish" class="button-31"
                                            role="button" style="margin-left: 100px">
                                            Previous
                                        </button>


                                        <button v-if="groupTalkStore.isLoading" class="button-31"
                                            style="margin-right: 100px">
                                            Saving...
                                        </button>
                                        <button v-else class="button-31" style="margin-right: 100px"
                                            @click="groupTalkStore.wizardFinished">
                                            Finish
                                        </button>



                                    </div>
                                </div>

                            </div>
                        </div>







                    </div>
                </div>
            </div>
        </div>



        <div v-if="groupTalkStore.showTextTalksLibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeTextTalksLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="groupTalkStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>

                            <div class="talkItem" v-for="(textTalk, index) in filteredTextTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ textTalk.title }}
                                    </div>


                                </div>

                                <div class="talkRight">

                                    <button
                                        @click="() => { groupTalkStore.setCurrentSafetyTalkTemplate(textTalk); groupTalkStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>


        <div v-if="groupTalkStore.showPreviewTalkFromLibraryModal" class="preview-modal-overlay">

            <div class="preview-modal-box" style="width: 900px">

                <div class="preview-model-box-header">

                    <div style="font-size: 17px; max-width: 500px; text-align: left;">
                        <b>{{ groupTalkStore.currentSafetyTalkTemplate.title }} </b>
                    </div>


                    <div>

                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'text'">

                            <button @click="populateSelectedContentInEditor()" class="btn btn-dark"
                                style="margin-right: 35px;">Use This Template</button>

                            <i @click="groupTalkStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>

                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'pdf'">

                            <button v-if="groupTalkStore.isLoading" class="btn btn-dark"
                                style="margin-right: 35px;"><b>Assigning PDF...</b></button>

                            <button v-else @click="groupTalkStore.setSelectedPDFfromLibrary" class="btn btn-dark"
                                style="margin-right: 35px;">Use This Template</button>

                            <i @click="groupTalkStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>

                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'video'">

                            <button v-if="groupTalkStore.isLoading" class="btn btn-dark"
                                style="margin-right: 35px;"><b>Assigning Video...</b></button>

                            <button v-else class="btn btn-dark" @click="groupTalkStore.populateTheSelectedVideo"
                                style="margin-right: 35px;">Use This Video</button>

                            <i @click="groupTalkStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>



                    </div>
                </div>



                <div class="preview-modal-content">

                    <div class="previewModalContentBody"
                        :class="{ previewModalContentBodyForVideo: groupTalkStore.currentSafetyTalkTemplate.content_type === 'video' }">


                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'text'">

                            <div v-html="groupTalkStore.currentSafetyTalkTemplate.body"></div>

                        </div>

                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'pdf'">


                            <vue-pdf-embed :source="groupTalkStore.currentSafetyTalkTemplate.pdf_url_path" />

                        </div>

                        <div v-if="groupTalkStore.currentSafetyTalkTemplate.content_type === 'video'">



                            <div v-html="groupTalkStore.currentSafetyTalkTemplate.video_embed_code"></div>

                        </div>




                    </div>



                </div>
            </div>
        </div>


        <div v-if="groupTalkStore.showEmbedVideoModal" class="modal-overlay">

            <div class="modal-box" style="width: 600px">

                <div class="model-box-header">
                    <div style="font-size: 17px; text-align: left;">
                        <b>Embed video</b>
                    </div>



                    <div>
                        <i @click="groupTalkStore.closeVideoEmbedModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>




                <div class="modal-content" style="padding-top: 10px;">


                    <div class="embedBox">

                        <textarea class="form-control" style="resize: none;" placeholder="Paste video embed code here..."
                            v-model="groupTalkStore.pastedEmbedVideoCode" cols="30" rows="10" required></textarea>

                        <button @click="groupTalkStore.setPastedCodeToRender" class="btn btn-dark"
                            style="width: 100%; margin-top: 15px;"><b>Add</b></button>


                    </div>




                </div>
            </div>
        </div>


        <div v-if="groupTalkStore.showVideoLibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeVideoLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="groupTalkStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>


                            <div class="talkItem" v-for="(talk, index) in filteredVideoTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ talk.title }}
                                    </div>


                                </div>
                                <div class="talkRight">



                                    <button
                                        @click="() => { groupTalkStore.setCurrentSafetyTalkTemplate(talk); groupTalkStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>

        <div v-if="groupTalkStore.showPDFlibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closePDFLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="groupTalkStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>


                            <div class="talkItem" v-for="(talk, index) in filteredPDFTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ talk.title }}
                                    </div>


                                </div>
                                <div class="talkRight">



                                    <button
                                        @click="() => { groupTalkStore.setCurrentSafetyTalkTemplate(talk); groupTalkStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>




    </div>
</template>

<script setup>

import { defineProps, onMounted, ref, computed } from "vue";
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { useGroupTalkStore } from "@/stores/GroupTalkStore";
import GroupWizardProgressBarVue from "@/components/GroupWizardProgressBar.vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import VuePdfEmbed from 'vue-pdf-embed'


const groupTalkStore = useGroupTalkStore();


// eslint-disable-next-line
const props = defineProps({
    uuid: String,
});


const editor = ref(
    useEditor({

        content: "",
        extensions: [
            StarterKit,
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'left',
                alignments: ['left', 'center', 'right'],

            }),
            Image.configure({
                allowBase64: true,
            })
        ],
        // eslint-disable-next-line 
        onUpdate: ({ editor }) => {
            updateContent();
        }

    })
);


const updateContent = () => {
    groupTalkStore.post.postData.content = editor.value.getHTML();
};



const triggerFileInput = () => {
    document.getElementById('fileInput').click();
};


const handleFileChange = async (event) => {
    try {
        // Wait for the image URL to be returned
        let imageUrl = await groupTalkStore.handleImageUploadToServer(event);

        // Add the image into the editor
        if (imageUrl) {
            editor.value.chain().focus().setImage({ src: imageUrl }).run();
        } else {
            alert('Failed to upload image');
        }
    } catch (error) {
        console.error(error);
        alert('Failed to upload image');
    }
};


const searchQuery = ref('');



const filteredTextTalks = computed(() => {

    return groupTalkStore.allTextTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            talk.body.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});


const filteredVideoTalks = computed(() => {

    return groupTalkStore.allVideoSafetyTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});

const filteredPDFTalks = computed(() => {

    return groupTalkStore.allPDFSafetyTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});


const populateSelectedContentInEditor = () => {


    groupTalkStore.post.postData.title = groupTalkStore.currentSafetyTalkTemplate.title;
    groupTalkStore.post.postData.content = groupTalkStore.currentSafetyTalkTemplate.body;

    if (editor.value) {

        let stringToRemove = "<div><br></div>"; //causes extra spaces
        let regex = new RegExp(stringToRemove, 'g');

        // Remove all instances of the string
        let updatedBody = groupTalkStore.currentSafetyTalkTemplate.body.replace(regex, '');

        // Set the content of the editor
        editor.value.commands.setContent(updatedBody);
    }


    groupTalkStore.closePreviewTalkLibraryModal()
    groupTalkStore.closeTextTalksLibraryModal()




}


onMounted(() => {

    groupTalkStore.fetchGroupTalkForWizard(props.uuid)


});

</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(170, 156, 156); */
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 45px;
    height: 100%;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.wizardWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    margin-top: 30px;
    /* height: 100%; */
}

.wizardOptionsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.goalItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: white;
    font-weight: bold;
    cursor: pointer;
}

.goalItem:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.goalItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.wizardQuestion {
    font-size: 25px;
}

.optionHeader {
    margin-top: 15px;
}

.theOptionIcon {
    font-size: 30px;
    font-weight: bold;
}

.textEditorBoxWrapper {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    width: 100%;
    /* background-color: #10cc2c; */
}

.textEditorBox {
    width: 850px;
    /* height: 700px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.outerWrapper {
    display: flex;
    justify-content: center;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

/* .button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
} */

.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
}

.aiBtn {
    margin-right: 10px;
    /* float: right; */
}

.tempBtn {
    float: right;
}

.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}

.quizBuilderWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* background-color: aquamarine; */
    width: 100%;
    margin-top: 20px;
}

.outerQuizWrapper {
    display: flex;
    justify-content: center;
}

.quizBuilderBox {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 700px;
    height: auto;
    /* background-color: #d5d9d9; */
    border-radius: 10px;
}

.quizBuilderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    /* height: 50px; */
    background-color: rgb(245, 243, 243);
}

.quizContent {
    padding: 20px;
    height: 550px;
    /* background-color: #d8a1a1; */
    overflow-y: auto;
}

.quizTitle {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.messageDetailsBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* background-color: #d5d9d9; */
    margin-top: 20px;
}

.messageBox {
    /* background-color: #0f1111; */
    width: 550px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.theLabel {
    float: left;
    font-weight: bold;
}

.saveOrPublishBox {
    margin-top: 30px;
}

.videoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}

.videoOuterBox {
    display: flex;
    justify-content: center;
}

.videoBox {
    width: 700px;
    /* background-color: #d5d9d9; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.videoContent {
    width: 100%;
    /* background-color: #d9e7ff; */
    /* height: 400px; */
}

.videoTitleWrapper {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}

.pdfWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pdfBox {
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* background-color: #d9e7ff; */
}

.pdfTitleWrapper {
    padding: 10px;
    width: 100%;
    /* background-color: #adbbd4; */
}

.pdfActionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdfTopWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    padding: 10px;
}

.theUploadBox {
    display: flex;
}

.pdfContent {
    width: 100%;
    height: 600px;
    overflow-y: auto;
    background-color: #e0eded;
}

.navBtnsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
    /* background-color: #679191; */
}

.button-31 {
    background-color: #222;
    border-radius: 5px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    height: 35px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
}

.button-31:hover,
.button-31:focus {
    opacity: 0.75;
}

.messageBoxOuterWrapper {
    display: flex;
    justify-content: center;
}

.pdfOuterWrapper {
    display: flex;
    justify-content: center;
}

/* .ProseMirror {
    padding: 10px;
    border: 2px solid black;
    outline: none;
    border-radius: 5px;

} */


.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    border-bottom: 2px solid rgb(79, 76, 76);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76);
    outline: none;
    min-height: 400px;
    text-align: left;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}


.textEditorWrapper {
    width: 100%;
    /* width: 100%; */
    /* background-color: red; */
}


.btnBox {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(79, 76, 76);
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.is-active {
    background-color: #d5d9d9;
    padding: 5px;
}

.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;

}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.modal-search-box {
    /* background-color: red; */
    margin-bottom: 15px;
}

.innerBox {
    /* background-color: red; */
    overflow-y: auto;
    width: 100%;
    height: 600px;
    padding: 12px;
}

.talkItem {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.talkItem:hover {
    background-color: rgb(249, 253, 252);
}

.talkLeft {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 80%;
    /* background-color: #aadada; */
}

.talkRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* padding: 10px; */
    /* background-color: #3ddbdb; */
}


.talkTitle {
    /* background-color: #d9e7ff; */
    text-align: left;
    font-weight: bold;
    padding: 6px;
}

.talkBody {
    /* background-color: #3f84fa; */
    text-align: left;
    padding-left: 5px;
    padding-bottom: 5px;
    /* margin-top: 7px; */
    /* padding: 6px; */

}



.preview-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 9999;
    /* Ensure the modal is on top */
}


/* Styles for the modal box */
.preview-modal-box {
    background-color: white;
    width: 900px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.preview-model-box-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 35px;
}

.preview-modal-content {
    text-align: center;
}

.previewModalContentBody {
    text-align: left;
    height: 700px;
    width: 100%;
    height: 700px;
    /* background-color: red; */
    overflow-y: auto;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
}

.previewModalContentBodyForVideo {
    text-align: left;
    height: 700px;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    overflow-y: auto;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
}


.ai-prompt-modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;

}

.ai-prompt-modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ai-prompt-model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.ai-prompt-modal-content {
    text-align: center;
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}



.ai-results-modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 9999;
}

.ai-results-modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.ai-results-model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.ai-results-modal-content {
    text-align: center;
}

.aiResultsbox {
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    height: 600px;
    /* background-color: red; */
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;

}

.aiTitle {
    background-color: #f2f4f3;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 15px;
}

.aiContent {
    /* background-color: rgb(176, 156, 156); */
    width: 100%;
    padding: 15px;
    text-align: left;
}

.answerBox {
    width: 100%;
    /* background-color: #90c1a9; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding-left: 10px;

}

.answerLeft {
    display: flex;
    flex-direction: column;
    width: 90%;
    /* background-color: #f3bfbf; */
    text-align: left;
}

.answerRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    /* background-color: #c19797; */
}


.questionItem {
    width: 100%;
    /* background-color: #f4ec13; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
}

.questionText {
    display: flex;
    flex-direction: row;
    font-size: 18p;
    font-weight: bold;
    text-align: left;
    /* background-color: aqua; */
}

.answerText {
    /* background-color: green; */
    text-align: left;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 15px;

}

.deleteQuestRight {
    width: 10%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: right;
}

.noQuestionsBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(189, 149, 149); */
    height: 100%;
}

.deletePDFbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: antiquewhite;
    width: 100%;
    padding: 10px;
}

.embedBox {
    display: flex;
    flex-direction: column;
}

.videoRenderedIframe {
    height: 395px;
    overflow: hidden;
    position: relative;
}
</style>
