/* eslint-disable */


import SupervisorDashService from "@/services/SupervisorDashService";
import { defineStore } from "pinia";
import moment from "moment";
import { toast } from "vue3-toastify";

export const useSupervisorDashboardStore = defineStore("supervisorDashboardStore", {

  state: () => ({


    groupTalkPosts: [],

    groupTalkPost: {},
    post: {},

    thisVideoIsYoutube: false,

    source1: "",
    doesPDFNameExistInLibrary: "",

    optionsArray: [

      { id: 1, name: 'Checkoff', type: 'checkoff', desc: 'Simply uncheck employees not in attendance' },
      { id: 2, name: 'Collect signatures', type: 'signature', desc: 'Select workers in attendance and pass around device to collect signatures' },
      { id: 3, name: 'Take photo of sign-in sheet', type: 'signed_sheet', desc: 'You can take a photo of the physical sign-in sheet' },

    ],

    showSignOffModalOption: false,

    currentMethodSelected: '',

    showCheckoffEmployeeModal: false,

    employees: [],
    employeesCheck: [],

    employeeData: {
      employeesCheck: [],
      time_acknowledged: ''
    },

    updateEmployeesCheck: [],

    showUpdateCheckoffModal: false,

    showEmployeesListToSelectSignaturesModal: false, 

    employeesToCollectSignatures: [],
    employeesSelectedForSignatures: [],

    showListOfEmployeesToCollectSignauresFrom: false,

    showSignatureBoxModal: false,

    currentEmployeeToCollectSignatureFrom: {},

    grouptTalkAcknowledgementsWithSignatures: [],

    showGroupTalkAcknowledgementsWithSignaturesModal: false,

    currentGroupAck: {},

    showRedoAckSignatureModal: false,

    isLoading: false,

    showTakeImageOfSheetModal: false,

    showSigninSheetPreviewModal: false,

 
  }),


  actions: {


    
    fetchAllGroupTalksForThisSupervisor(){

      SupervisorDashService.getAllGroupTalksForASupervisor()
        .then((response) => {


          this.groupTalkPosts = response.data.groupTalkPosts
          console.log(response.data)
          
        }).catch((err) => {

          console.log(err) 
          
        });

    },


    fetchGroupTalkPost(uuid){

      this.post = {}
      this.thisVideoIsYoutube = false
      this.doesPDFNameExistInLibrary = ""
      this.source1 = ""

      SupervisorDashService.getAGroupTalkForASupervisor(uuid)
          .then((response) => {

            this.groupTalkPost = response.data.groupTalkPost
            this.post = response.data.groupTalkPost.post

            if (this.post.message_goal_id == 3) {

              //write a function to return if video_embed_code includes 'youtube'
              if (this.isThisVideoYoutube()) {
  
                this.thisVideoIsYoutube = true
  
  
              }
  
            }
  
            if (this.post.message_goal_id == 2) {
  
  
              this.doesPDFNameExistInLibrary = response.data.doesExistInLibrary
  
              this.buildPDFurl2()
  
  
            }

            console.log(response.data)
            
          }).catch((err) => {

            console.log(err)
            
          });

    },

    isThisVideoYoutube() {

      // Check if videoCode includes 'youtube' and return true or false
      return this.post.video_embed_code.includes('youtube');

    },


    buildPDFurl2() {

      let url = "";

      if (this.doesPDFNameExistInLibrary === 1) {

        url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/pdftalks/" + this.post.pdf_url_path;

        this.source1 = url;

        return;
      }

      if (this.doesPDFNameExistInLibrary === 0) {

         //if master_account_admin_id is null it means it was created by the master manager account
         if(this.post.master_account_admin_id == null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.post.manager_id + "/9j1ks/" + this.post.pdf_url_path;

        }

        //this is mean it was created by a master account admin
        if(this.post.master_account_admin_id !== null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.post.master_account_admin_id + "/9j1ks/" + this.post.pdf_url_path;

        }


        this.source1 = url;

        // console.log(url)
        // console.log(this.source1)

        return;
      }


    },


    triggerOpenCorrectModal(type){

      if(type === 'checkoff'){

        this.currentMethodSelected = type
        this.fetchEmployeesForATeamForCheckoff(this.groupTalkPost.uuid)
        this.openCheckoffModal()

      }

      if(type === 'signature'){


        this.currentMethodSelected = type
        this.fetchEmployeesForATeamForSignaturesOnly(this.groupTalkPost.uuid)
        this.openEmployeesListToSelectSignaturesModal()

      }

      if(type === 'signed_sheet'){

        this.currentMethodSelected = type
        this.openTakeImageOfSheetModal()


      }

    },

    openSignOffModalOption(){
      this.showSignOffModalOption = true
    },

    closeSignOffModalOption(){
      this.showSignOffModalOption = false
    },

    openCheckoffModal(){
      this.showCheckoffEmployeeModal = true
    },

    closeCheckoffModal(){
      this.showCheckoffEmployeeModal = false
    }, 

    fetchEmployeesForATeamForCheckoff(uuid){

      SupervisorDashService.getEmployeesForATeamForCheckoffOnly(uuid)
        .then((response) => {

          this.employees = response.data.employees

          //map to employeesCheck array to uncheck those not present
          this.employees.forEach(obj => {

            this.employeesCheck.push({
              user_id: obj.id,
              name: obj.name,
              checked: 1
            });

          })

          console.log(response.data)
          
        }).catch((err) => { 

          console.log(err)
          
        });

    },

    updatePresentStatus(employee) {

      //if the status id true/1 switch to false
      if (employee.checked) {

        employee.checked = 0

      } else {
        employee.checked = 1
      }


    },


    markCheckoffAsComplete() {


      this.employeeData.employeesCheck = this.employeesCheck
      this.employeeData.time_acknowledged = this.getCurrentTime()

      // console.log(this.employeeData, this.groupTalkPost.uuid)

      SupervisorDashService.markCheckoffTalkAcknowledgement(this.groupTalkPost.uuid, this.employeeData)
        .then((response) => {



          this.groupTalkPost.is_acknowledged = 1
          this.groupTalkPost.completion_type = 'checkoff'

          this.closeCheckoffModal()
          this.closeSignOffModalOption()

          toast("Talk marked as completed!", {
            position: "bottom-right",
            autoClose: 2000,
          });

          // eslint-disable-next-line no-console
          console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    
    },

    fetchEmployeesAcknowledgementStatusForCheckoff() {

      SupervisorDashService.getCheckoffEmployeesWhoAcknowledgedAGroupTalkPost(this.groupTalkPost.uuid)
        .then((response) => {

          this.updateEmployeesCheck = response.data.employeesCheck

          // eslint-disable-next-line no-console
          console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    getCurrentTime() { 

      return moment().format('HH:mm');

    },

    openUpdateCheckoffModal(){
      this.showUpdateCheckoffModal = true
    },

    closeUpdateCheckoffModal(){
      this.showUpdateCheckoffModal = false
    },

    updateAcknowledgementStatus() {

      this.employeeData.employeesCheck = this.updateEmployeesCheck


      SupervisorDashService.updateCheckoffGroupAcknowledgement(this.groupTalkPost.uuid, this.employeeData)
        .then((response) => {


          this.closeUpdateCheckoffModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 2000,
          });

          // eslint-disable-next-line no-console
          console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    openEmployeesListToSelectSignaturesModal(){
      this.showEmployeesListToSelectSignaturesModal = true
    },

    closeEmployeesListToSelectSignaturesModal(){
      this.showEmployeesListToSelectSignaturesModal = false
    }, 


    
    fetchEmployeesForATeamForSignaturesOnly(){

      this.employeesToCollectSignatures = []

      SupervisorDashService.getEmployeesForATeamForSignaturesOnly(this.groupTalkPost.uuid)
        .then((response) => {

          this.employeesToCollectSignatures = response.data.employees

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    addUserToSignatureArray(user) {

      // Check if the user is already in the array
      const index = this.employeesSelectedForSignatures.findIndex(emp => emp.the_user.id === user.id);
    
      if (index !== -1) {
        // If the user is already in the array, remove them
        this.employeesSelectedForSignatures.splice(index, 1);
      } else {
        // If the user is not in the array, add them

        let newobj = {
          the_user: user,
          sign_image: null
        }

        this.employeesSelectedForSignatures.push(newobj);
      }

    },


    openListOfEmployeesToCollectSignauresFrom(){ 
      this.showListOfEmployeesToCollectSignauresFrom = true
    },


    closeListOfEmployeesToCollectSignauresFrom(){
      this.showListOfEmployeesToCollectSignauresFrom = false
    },



    openSignatureBoxModal(){
      this.showSignatureBoxModal = true
    },

    closeSignatureBoxModal(){
      this.showSignatureBoxModal = false
    },

    setCurrentEmployeeToCollectSignatureFrom(user){
      this.currentEmployeeToCollectSignatureFrom = user
      this.openSignatureBoxModal() 
    },

    setSignatureImage(data){ 

      this.currentEmployeeToCollectSignatureFrom.sign_image = data

      this.closeSignatureBoxModal()

      console.log(this.currentEmployeeToCollectSignatureFrom, data)

    },

    redoSignauture(employee){

      if (
        confirm(
          "Are you sure you want redo this signature?"
        )
      ) {


        this.setCurrentEmployeeToCollectSignatureFrom(employee)
        this.openSignatureBoxModal()


      }


    },

    signatureFinalSignOff(){
 

      if (
        confirm(
          "Are you sure you're ready to do the final sign off for this talk?"
        )
      ) {


        //if any sign_image is empty show alert and stop code/return
        if (this.hasAnyMissingSignature()) {
          alert("Please make sure all signatures are added!");
          return;
        }



        const formData = {
          employeesData: this.employeesSelectedForSignatures,
          time_acknowledged: this.getCurrentTime()
        }

        SupervisorDashService.markSignaturesTalkAcknowledgement(this.groupTalkPost.uuid, formData)
          .then((response) => {

            this.fetchGroupTalkPost(this.groupTalkPost.uuid)

            this.closeListOfEmployeesToCollectSignauresFrom()
            this.closeEmployeesListToSelectSignaturesModal()
            this.closeSignOffModalOption()

            toast("Talk marked as completed!", {
              position: "bottom-right",
              autoClose: 2000,
            });

            this.groupTalkPost.is_acknowledged = 1
            this.groupTalkPost.completion_type = 'signature'

            console.log(response.data)
            
          }).catch((err) => {

            console.log(err)
            
          });
  



      }


    

    },


    hasAnyMissingSignature() {
      return this.employeesSelectedForSignatures.some(emp => emp.sign_image == null);
    },


    fetchllEmployeesWhoAlreadyHadSignaturesDoneForATalk(){

      SupervisorDashService.getAllEmployeesWhoAlreadyHadSignaturesDoneForATalk(this.groupTalkPost.uuid)
        .then((response) => {


          this.grouptTalkAcknowledgementsWithSignatures = response.data.grouptTalkacknowledgements

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });


    },

    openGroupTalkAcknowledgementsWithSignaturesModal(){ 
      this.showGroupTalkAcknowledgementsWithSignaturesModal = true
    },

    closeGroupTalkAcknowledgementsWithSignaturesModal(){
      this.showGroupTalkAcknowledgementsWithSignaturesModal = false
    },

    openFetchAckDataAndOpenModal(){

      // console.log('yes')

      this.fetchllEmployeesWhoAlreadyHadSignaturesDoneForATalk()
      this.openGroupTalkAcknowledgementsWithSignaturesModal()

    },

    redoAckSignauture(ack){

      if (
        confirm(
          "Are you sure you want redo this signature?"
        )
      ) {


        this.setCurrentAck(ack)
        this.openRedoAckSignatureModal()


        // this.setCurrentEmployeeToCollectSignatureFrom(employee)
        


      }


    },

    setRedoAckSignatureImage(data){ 

      this.isLoading = true

      this.currentGroupAck.new_image_file = data

      const formData = {
        ack: this.currentGroupAck
      }

      SupervisorDashService.uploadRedoSignatureImage(formData)
        .then((response) => {


          let new_url = response.data.new_img_url

          this.currentGroupAck.signature_img_url = new_url

          this.closeRedoAckSignatureModal()

          toast("Signature updated!", {
            position: "bottom-right",
            autoClose: 2000,
          });


          this.isLoading = false


          console.log(response.data)
          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });

      // console.log(data)

    },

    setCurrentAck(ack){
      this.currentGroupAck = ack
    },


    openRedoAckSignatureModal(){
      this.showRedoAckSignatureModal = true
    },

    closeRedoAckSignatureModal(){
      this.showRedoAckSignatureModal = false
    },



    openTakeImageOfSheetModal(){
      this.showTakeImageOfSheetModal = true
    },

    closeTakeImageOfSheetModal(){
      this.showTakeImageOfSheetModal = false
    }, 


    finalMarkUploadSignInSheetAsComplete(theImage){ 

      this.isLoading = true

      const formData = {
        img_file: theImage,
        time_acknowledged: this.getCurrentTime()
      }

      // console.log(formData)

      SupervisorDashService.markUploadSignInSheetAsComplete(formData, this.groupTalkPost.uuid)
          .then((response) => {

            this.closeTakeImageOfSheetModal()
            this.closeSignOffModalOption()
            this.groupTalkPost.is_acknowledged = 1
            this.groupTalkPost.completion_type = 'signed_sheet'


            this.isLoading = false


            toast("Talk completed!", {
              position: "bottom-right",
              autoClose: 2000,
            });

            console.log(response.data)
            
          }).catch((err) => {

            this.isLoading = false

            console.log(err)
            
          });

    },



    openSigninSheetPreviewModal(){
      this.showSigninSheetPreviewModal = true
    },

    closeSigninSheetPreviewModal(){
      this.showSigninSheetPreviewModal = false
    }, 


    downloadImageFile() { 


      let downloadURL = this.groupTalkPost.sign_in_sheet_img_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = 'signin_sheet'; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },




    



  },


});
