/* eslint-disable */

import AuthService from "@/services/AuthService";
import apiClient from "@/services/MyClient";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

export const useAuthStore = defineStore("authStore", {
  state: () => ({

    loginFormData: {
      email: "",
      password: "",
      device_name: "web",
    },

    email: "", //used for verifying auth code ad forgot password reset

    resetPasswordData: {
      email: "",
      password: "",
      password_confirmation: "",
      token: "",
    },

    token: null,

    user: {
      fname: "", //username
      rid: null, //role id
    },


    superAdminRegisterFormData: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      device_name: "web",
    },


    managerRegisterFormData: {
      company_name: "",
      fname: "",
      lname: "",
      country_id: null,
      phone_number: "",
      email: "",
      password: "",
      password_confirmation: "",
      device_name: "web",
    },

    employeeRegisterFormData: {
      company_id: null,
      team_id: null,
      app: null,
      fname: "",
      lname: "",
      email: "",
      password: "",
      password_confirmation: "",
      device_name: "web",
    },

    subAdminRegisterFormData: { 
      company_id: null,
      app: null,
      fname: "",
      lname: "",
      email: "",
      password: "",
      password_confirmation: "",
      device_name: "web",
    },

    authCode: null,
    isLoading: false,
    errorMessage: "",
    successMessage: "",
    errorArray: [],

    countries: [
      {
        id: 1,
        name: "United States",
      },

      {
        id: 2,
        name: "Canada",
      },

      {
        id: 2,
        name: "Uinted Kingdom",
      },
    ],


    currentUser: {},

    passwordData: {

      current_password: "",
      new_password: "",
      confirm_new_password: ""

    },


    imageData: "",

    imageFileToSendToDB: {

      image_name: "",
      image_file: "",

    },

    showInpectionsObersvationsModal: false,
    showSafetyDocsPolicyModal: false,
    showToolboxTalkModal: false,
    showSafetyDateSheetsModal: false,


    appsAccess: [],
    appAccessList: [],
    currentAppAccess: {}


  }),

  persist: {
    merge: "merge",
    paths: ["token", "user"], //only persist these in local storage
  },

  actions: {

    registerManager() {
      this.isLoading = true;
      this.errorMessage = "";
      this.errorArray = [];


      //Set email is state to verify email
      this.email = this.managerRegisterFormData.email

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.registerThisManager(this.managerRegisterFormData)
            .then((response) => {

              this.isLoading = false;
              this.router.push({ name: "VerifyAuthCode" });


              // console.log(response.data);
            })
            .catch((err) => {
              this.isLoading = false;

              if (
                err.response &&
                err.response.data &&
                err.response.data.errors
              ) {
                let theErrors = err.response.data.errors;

                Object.values(theErrors).forEach((errorArray) => {
                  errorArray.forEach((error) => {
                    this.errorArray.push(error);
                  });
                });
                console.log(theErrors);
              } else {
                this.isLoading = false;
                // Handle the case where the error format is not as expected
                console.log("An unexpected error occurred:", err);
              }
            });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err.data);
        });
    },

    verifyUserAuthCode() {
      this.errorMessage = "";

      const formData = {
        email: this.email,
        auth_code: this.authCode,
      };

      AuthService.verifyThisAuthCode(formData)
        .then((response) => {
          if (response.data.message === 1) {
            this.router.push({ name: "Login" });
          } else if (response.data.message === 2) {
            this.errorMessage =
              "The 6 digit code you entered doesn't match what was sent in the email. Please check again.";
          } else {
            this.errorMessage =
              "Oops, something went wrong. Please contact support.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },



    async login() {
      this.isLoading = true;
      this.errorMessage = "";

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.loginUser(this.loginFormData)
            .then((response) => {
              this.isLoading = false;

              //set token
              this.token = response.data.token;

              //store some user info
              this.user.fname = response.data.user.fname;
              this.user.rid = response.data.user.role_id;

              //important: add upate the header with token
              apiClient.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${this.token}`;
                return config;
              });

              this.router.push({ name: "HomeDefault" });

              // console.log(response.data);
            })
            .catch((err) => {
              this.isLoading = false;

              // this.errorMessage = err.response.data.error
              this.errorMessage =
                "Unauthorised. Please make sure your email and password are correct.";

              console.log(err);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    forgotPassword() {
      this.errorMessage = "";
      this.successMessage = "";

      const formData = {
        email: this.email,
      };

      this.isLoading = true;

      AuthService.userForgotPasssword(formData)
        .then((response) => {
          this.isLoading = false;
          this.successMessage = "We have emailed your password reset link";

          // console.log(response.data);
        })
        .catch((err) => {
          this.isLoading = false;
          this.errorMessage = err.response.data.message;

          console.log(err.response.data.message);
        });
    },

    resetPassword() {
      this.errorMessage = "";

      // Check password length
      if (this.resetPasswordData.password.length < 8) {
        this.errorMessage = "Password must be at least 8 characters";

        return;
      }

      //check if passwords match
      if (
        this.resetPasswordData.password ===
        this.resetPasswordData.password_confirmation
      ) {
        const formData = {
          email: this.resetPasswordData.email,
          password: this.resetPasswordData.password,
          password_confirmation: this.resetPasswordData.password_confirmation,
          token: this.resetPasswordData.token,
        };

        AuthService.userResetPassword(formData)
          .then((response) => {
            this.router.push({ name: "Login" });

            // console.log(response.data);
          })
          .catch((err) => {
            this.errorMessage =
              err.response.data.message +
              " Make sure you typed your email correctly.";

            // console.log(err)
          });
      } else {
        this.errorMessage = "Passwords do not match";

        console.log("not matched");
      }
    },

    async logoutUser() { 
      AuthService.logout()
        .then((response) => {
          //remove token from localstorage
          this.token = "";

          //clear login data from
          this.user.fname = "";
          this.user.rid = null;

          window.localStorage.clear();

          this.router.push({ name: "Login" });

          // console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    registerEmployee() {

      this.isLoading = true;
      this.errorMessage = "";
      this.errorArray = [];

      //Set email is state to verify email
      this.email = this.employeeRegisterFormData.email

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.registerNewEmployee(this.employeeRegisterFormData)
            .then((response) => {

              this.isLoading = false;

              if(response.data.message === 'proceed'){

                this.router.push({ name: "VerifyAuthCode" });
                // console.log('redirect to verify as usual')
                
              }


              if(response.data.message === 'limit_reached'){
               
                alert('User limit reached. Please contact account manager.')
                
                return

              }
              
              

              
            })
            .catch((err) => {
              this.isLoading = false;

              if (
                err.response &&
                err.response.data &&
                err.response.data.errors
              ) {
                let theErrors = err.response.data.errors;

                Object.values(theErrors).forEach((errorArray) => {
                  errorArray.forEach((error) => {
                    this.errorArray.push(error);
                  });
                });
                console.log(theErrors);
              } else {
                this.isLoading = false;
                // Handle the case where the error format is not as expected
                console.log("An unexpected error occurred:", err);
              }
            });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err.data);
        });



    },


    getUser() {

      AuthService.getUser()
        .then((response) => {


          this.currentUser = response.data

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    updateMyDetails() {

      AuthService.updateMyAccountDetails(this.currentUser)
        .then((response) => {


          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // console.log(response.data) 


        }).catch((err) => {


          // eslint-disable-next-line no-console
          console.log(err)

        });


    },



    updateMyPassword() {


      this.errorMsg = ""


      if (this.passwordData.new_password !== this.passwordData.confirm_new_password) {

        alert('The new password and confirm password do not match')

        return

      }


      AuthService.updatePassword(this.passwordData)
        .then((response) => {


          if (response.data.error) {
            this.errorMsg = response.data.error
          } else {

            this.errorMsg = "",

              this.passwordData.current_password = ""
            this.passwordData.new_password = ""
            this.passwordData.confirm_new_password = ""



            toast("Updated!", {
              position: "bottom-right",
              autoClose: 1000,
            });


          }

          // // eslint-disable-next-line no-console
          // console.log(response.data)



        }).catch((error) => {


          // eslint-disable-next-line no-console
          console.log(error)

        });


    },


    previewImage(event) {

      var input = event.target;

      //genereate a uniqe name
      const randomString = Math.random().toString(36).substring(2, 7);

      this.imageFileToSendToDB.image_file = event.target.files[0];
      this.imageFileToSendToDB.image_name = randomString + event.target.files[0].name;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {

        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();

        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;


        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);


      }

    },


    uploadProfileImage() {

      const formData = new FormData

      formData.set('image_name', this.imageFileToSendToDB.image_name)
      formData.set('image_file', this.imageFileToSendToDB.image_file)

      AuthService.updateProfileAvatar(formData)
        .then(() => {



          this.getUser()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

      // // eslint-disable-next-line no-console
      // console.log(this.imageFileToSendToDB)

    },


    registerSubAdmin() {


      // console.log(this.subAdminRegisterFormData)

      this.isLoading = true;
      this.errorMessage = "";
      this.errorArray = [];

      //Set email is state to verify email
      this.email = this.subAdminRegisterFormData.email

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.registerSubAdmin(this.subAdminRegisterFormData)
            .then((response) => {

              this.isLoading = false;

              if(response.data.message === 'proceed'){

                this.router.push({ name: "VerifyAuthCode" });
                // console.log('redirect to verify as usual')
                
              }


              if(response.data.message === 'sub_manager_feature_not_enabled'){
               
                alert('Sub manager feature is not enabled. Please contact account manager')
                
                return

              }

              if(response.data.message === 'limit_reached'){
               
                alert('Sub manager limit reached. Please contact account manager.')
                
                return

              }


              console.log(response.data);
            })
            .catch((err) => {
              this.isLoading = false;

              if (
                err.response &&
                err.response.data &&
                err.response.data.errors
              ) {
                let theErrors = err.response.data.errors;

                Object.values(theErrors).forEach((errorArray) => {
                  errorArray.forEach((error) => {
                    this.errorArray.push(error);
                  });
                });
                console.log(theErrors);
              } else {
                this.isLoading = false;
                // Handle the case where the error format is not as expected
                console.log("An unexpected error occurred:", err);
              }
            });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err.data);
        });







    },


    goToAccountPageForManager() {

      this.router.push({ name: "UserAccount" });

    },

    goToAccountPageForEmployee() {

      this.router.push({ name: "EmployeeAccount" });

    },


    openInpectionsObersvationsModal() {
      this.showInpectionsObersvationsModal = true
    },

    closeInpectionsObersvationsModal() {
      this.showInpectionsObersvationsModal = false
    },


    openSafetyDocsPolicyModal() {
      this.showSafetyDocsPolicyModal = true
    },

    closeSafetyDocsPolicyModal() {
      this.showSafetyDocsPolicyModal = false
    },


    registerSuperAdmin() {

      this.isLoading = true;
      this.errorMessage = "";
      this.errorArray = [];

      //Set email is state to verify email
      this.email = this.superAdminRegisterFormData.email

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.registerSuperAdminUser(this.superAdminRegisterFormData)
            .then((response) => {
              this.isLoading = false;
              this.router.push({ name: "VerifyAuthCode" });

              // console.log(response.data);
            })
            .catch((err) => {
              this.isLoading = false;

              if (
                err.response &&
                err.response.data &&
                err.response.data.errors
              ) {
                let theErrors = err.response.data.errors;

                Object.values(theErrors).forEach((errorArray) => {
                  errorArray.forEach((error) => {
                    this.errorArray.push(error);
                  });
                });
                console.log(theErrors);
              } else {
                this.isLoading = false;
                // Handle the case where the error format is not as expected
                console.log("An unexpected error occurred:", err);
              }
            });
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err.data);
        });



    },



    fetchAllAppsForTilePage() { 
      AuthService.getAllAppsForManagerAppsPage()
        .then((response) => {

          this.appsAccess = response.data.appsAccess
          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });
    },



    showAppMarketingModal(app) {

      if (app.short_name === 'talks') {

        this.openToolboxTalkModal()

      }

      if (app.short_name === 'sop') {

        this.openSafetyDocsPolicyModal()

      }

      if (app.short_name === 'safety_data_sheets') {

        this.openOpenSafetyDataSheetskModal()

      }

      if (app.short_name === 'inspections') {

        this.openInpectionsObersvationsModal()

      }

    },

    openToolboxTalkModal() { 
      this.showToolboxTalkModal = true
    },


    closeToolboxTalkModal() {
      this.showToolboxTalkModal = false
    },


    openOpenSafetyDataSheetskModal() {
      this.showSafetyDateSheetsModal = true
    },

    openCloseSafetyDataSheetskModal() {
      this.showSafetyDateSheetsModal = false
    },


    getAManagerAppAccessInfo(short_name) {

      AuthService.getAManagerAppsAccessStatus() 
        .then((response) => {


          this.appAccessList = response.data.appAccessList;

          const appToCheckIndex = this.appAccessList.findIndex(
            (appAccess) => appAccess.app && appAccess.app.short_name === short_name
          );

          if (appToCheckIndex !== -1) {

            this.currentAppAccess = this.appAccessList[appToCheckIndex];


            if (this.currentAppAccess.has_access === 'no') {
              this.router.push({ name: "AppsHome" });
            }



          } else {
            this.currentAppAccess = null; // or any fallback value you deem appropriate
          }



          console.log(appToCheckIndex)





        }).catch((err) => {

          console.log(err)

        });
    },

    checkThisEmployeeAppsAccessStatus(short_name){

      // console.log(short_name)

    },


    getAEmployeeAppAccessInfo() {

      AuthService.getAEmployeeAppsAccessStatus()
        .then((response) => {


          this.appAccessList = response.data.appAccessList

          console.log(response.data)


        }).catch((err) => {

          console.log(err)

        });
    }, 


    getASupervisorAppAccessInfo() { 

      AuthService.getASupervisorAppsAccessStatus()
        .then((response) => {


          this.appAccessList = response.data.appAccessList

          console.log(response.data)


        }).catch((err) => {

          console.log(err)

        });
    }, 
    



    checkSubManagerFeatureAccess() {


      const appToCheckIndex = this.appAccessList.findIndex(
        (appAccess) => appAccess.app.short_name === 'talks'
      );

      // console.log(appToCheckIndex)


    },


    




  },
});
