<template>
    <div class="mainWrapper">



        <MainSideBarVue />



        <div class="rightContentWrapper"> 


            <div class="topNav">
                <TopBarVue />
            </div>


            <div class="innerContainer">


                <div class="backBtnBox">


                    <div>

                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>

                    </div>


                    <!-- {{ campaignStore.postDetails }} -->


                    <div class="theHeaderWrapper">

                        <div>
                            Edit Safety Talk
                        </div>



                    </div>


                </div>



                <div class="editContentBox">




                    <div class="theContent">



                        <div v-if="campaignStore.postDetails.message_goal_id == 1">

                            <div class="textEditorBox">


                                <div class="textEditorHeader">
                                    <div style="width: 50%">
                                        <b style="float: left">Text Editor</b>
                                    </div>

                                    <div class="editorBtns">

                                        <button @click="campaignStore.savePostData" role="button" class="button-13">Save
                                            Changes</button>

                                    </div>
                                </div>

                                <div class="titleBox">
                                    <input v-model="campaignStore.postDetails.title" type="text"
                                        class="form-control titleInput" placeholder="Safety talk title..." />
                                </div>

                                <div class="contentInputBody">

                                    <div class="textEditorWrapper">



                                        <div v-if="editor">

                                            <div class="btnBox">


                                                <div class="btnItem" @click="editor.chain().focus().toggleBold().run()"
                                                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('bold') }">

                                                    <i class="bi bi-type-bold"></i>

                                                </div>

                                                <div class="btnItem" @click="editor.chain().focus().toggleItalic().run()"
                                                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('italic') }">

                                                    <i class="bi bi-type-italic"></i>

                                                </div>


                                                <div class="btnItem" @click="editor.chain().focus().toggleUnderline().run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('underline') }">

                                                    <i class="bi bi-type-underline"></i>

                                                </div>

                                                <div class="btnItem" @click="editor.chain().focus().setParagraph().run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('paragraph') }">

                                                    <i class="bi bi-paragraph"></i>

                                                </div>



                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('heading', { level: 3 }) }">

                                                    <i class="bi bi-type-h3"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('heading', { level: 4 }) }">

                                                    <i class="bi bi-type-h4"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('heading', { level: 5 }) }">

                                                    <i class="bi bi-type-h5"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('heading', { level: 6 }) }">

                                                    <i class="bi bi-type-h6"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().setTextAlign('left').run()"
                                                    :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'left' }) }">

                                                    <i class="bi bi-text-left"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().setTextAlign('center').run()"
                                                    :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'center' }) }">

                                                    <i class="bi bi-text-center"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().setTextAlign('right').run()"
                                                    :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'right' }) }">

                                                    <i class="bi bi-text-right"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleBulletList().run()"
                                                    :class="{ 'btnItemSelected': editor.isActive('bulletList') }">

                                                    <i class="bi bi-list-task" style="font-size: 20px;"></i>

                                                </div>

                                                <div class="btnItem"
                                                    @click="editor.chain().focus().toggleOrderedList().run()"
                                                    :class="{ 'is-active': editor.isActive('orderedList') }">
                                                    <i class="bi bi-list-ol" style="font-size: 20px;"></i>
                                                </div>



                                                <div class="btnItem" @click="triggerFileInput">

                                                    <i class="bi bi-card-image"></i>

                                                    <input type="file" id="fileInput" hidden @change="handleFileChange"
                                                        accept="image/*">




                                                </div>




                                            </div>

                                        </div>



                                        <EditorContent :editor="editor" />

                                    </div>


                                </div>
                            </div>

                        </div>


                        <div v-if="campaignStore.postDetails.message_goal_id == 2">

                            <div class="pdfOuterWrapper">

                                <div class="pdfBox">

                                    <div class="pdfTopWrapper">

                                        <div class="pdfActionsWrapper">

                                            <!-- {{ campaignStore.postDetails }} -->


                                            <div>

                                                <button @click="campaignStore.savePostData" role="button"
                                                    class="button-13">Save
                                                    Changes</button>


                                            </div>

                                            <form enctype="multipart/form-data"
                                                @submit.prevent="campaignStore.saveUpdatedPDF">

                                                <div class="theUploadBox">


                                                    <input class="form-control" type="file"
                                                        @change="campaignStore.onChangePDFupdate" accept=".pdf" required />

                                                    <button type="submit" class="button-13" style="margin-left: 5px">
                                                        Upload
                                                    </button>


                                                </div>

                                            </form>


                                        </div>
                                    </div>

                                    <div class="pdfTitleWrapper">
                                        <input v-model="campaignStore.postDetails.title" type="text"
                                            class="form-control titleInput" placeholder="Safety talk title..." />
                                    </div>


                                    <div v-if="campaignStore.isLoading">

                                        <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>

                                    </div>

                                    <div v-else>


                                        <div class="pdfContent">


                                            <!-- {{ campaignStore.source1 }}
                    -->


                                            <vue-pdf-embed :source="campaignStore.source1" />


                                        </div>

                                    </div>





                                </div>
                            </div>

                        </div>


                        <div v-if="campaignStore.postDetails.message_goal_id == 3">


                            


                            <div class="videoWrapper">

                                <div class="videoOuterBox">
                                    <div class="videoBox">
                                        <div class="videoHeader">
                                            <div><b>Video</b></div>

                                            <div>

                                                <button @click="campaignStore.openVideoEmbedModal" class="button-13"
                                                    style="margin-right: 10px">
                                                    Embed
                                                </button>


                                                <button 
                                                @click="campaignStore.updateVideoPost"
                                                    class="button-13">Save Changes</button>


                                            </div>
                                        </div>

                                        <div class="videoTitleWrapper">
                                            <input v-model="campaignStore.postDetails.title" type="text"
                                                class="form-control titleInput" placeholder="Safety talk title..." />
                                        </div>


                                        <div class="videoContent">


                                            <div v-if="campaignStore.renderVideoEmbedCode === ''"
                                                style="padding-bottom: 30px;">

                                                No video added yet.

                                            </div>

                                            <div v-else>

                                                <div v-html="campaignStore.renderVideoEmbedCode"
                                                    class="videoRenderedIframe"></div>

                                            </div>




                                        </div>
                                    </div>
                                </div>



                            </div>





                        </div>






                    </div>





                </div>









            </div>






        </div>




        <div v-if="campaignStore.showEmbedVideoModal" class="modal-overlay">

            <div class="modal-box" style="width: 600px">

                <div class="model-box-header">
                    <div style="font-size: 17px; text-align: left;">
                        <b>Embed video</b>
                    </div>



                    <div>
                        <i @click="campaignStore.closeVideoEmbedModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>




                <div class="modal-content" style="padding-top: 10px;">


                    <div class="embedBox">

                        <textarea class="form-control" style="resize: none;" placeholder="Paste video embed code here..."
                            v-model="campaignStore.pastedEmbedVideoCode" cols="30" rows="10" required></textarea>

                        <button @click="campaignStore.setPastedCodeToRender" class="btn btn-dark"
                            style="width: 100%; margin-top: 15px;"><b>Add</b></button>


                    </div>




                </div>
            </div>
        </div>




    </div>
</template> 

<script setup>

import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { defineProps, onMounted, ref } from 'vue';
import { useCampaignStore } from "@/stores/CampaignStore";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import CampaignService from "@/services/CampaignService";
import VuePdfEmbed from 'vue-pdf-embed'



const campaignStore = useCampaignStore();

const props = defineProps({
    random_string: String
})



const editor = ref(
    useEditor({

        content: "",
        extensions: [
            StarterKit,
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'left',
                alignments: ['left', 'center', 'right'],

            }),
            Image.configure({
                allowBase64: true,
            })
        ],
        // eslint-disable-next-line 
        onUpdate: ({ editor }) => {
            updateContent();
        }

    })
);


const updateContent = () => {

    campaignStore.postDetails.body = editor.value.getHTML();

    // console.log("UPDATED: ", campaignStore.postDetails.body)

};





const triggerFileInput = () => {
    document.getElementById('fileInput').click();
};


const handleFileChange = async (event) => {
    try {
        // Wait for the image URL to be returned
        let imageUrl = await campaignStore.handleImageUploadToServer(event);

        // Add the image into the editor
        if (imageUrl) {
            editor.value.chain().focus().setImage({ src: imageUrl }).run();
        } else {
            alert('Failed to upload image');
        }
    } catch (error) {
        console.error(error);
        alert('Failed to upload image');
    }
};


const cleanedHTMLBody = () => {


    let stringToRemove = "<div><br></div>"; //causes extra spaces
    let regex = new RegExp(stringToRemove, 'g');

    // Remove all instances of the string
    let updatedBody = campaignStore.postDetails.body.replace(regex, '');

    return updatedBody;


}

const fetchPostDetails = () => {

    CampaignService.getPostContent(props.random_string)
        .then((response) => {

            campaignStore.postDetails = response.data.post;


            if (campaignStore.postDetails.message_goal_id === "1") {

                let body = cleanedHTMLBody()

                editor.value.commands.setContent(body);


            }

            if (campaignStore.postDetails.message_goal_id === "2") {


                campaignStore.doesPDFNameExistInLibrary = response.data.doesExistInLibrary
                campaignStore.old_pdf_name = response.data.post.pdf_url_path;
                campaignStore.updated_pdf_title = response.data.post.title

                campaignStore.buildPDFurl2()



            }


            if (campaignStore.postDetails.message_goal_id === "3") {


                campaignStore.renderVideoEmbedCode = response.data.post.video_embed_code
                // campaignStore.postDetails.title = response.data.post.title
                // campaignStore.postDetails.video_embed_code = response.data.post.video_embed_code


            }


            // this.renderVideoEmbedCode = response.data.post.video_embed_code
            // this.videoPost.title = response.data.post.title
            // this.videoPost.video_embed_code = response.data.post.video_embed_code

            // this.buildPDFurl()





            // eslint-disable-next-line no-console
            console.log(response.data);

        }).catch((err) => {

            // eslint-disable-next-line no-console
            console.log(err);

        });

}




onMounted(() => {



    fetchPostDetails()




})







</script>



<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.rightContentWrapper {

    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
}


.theHeaderWrapper {
    /* text-align: left; */
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}


.backBtnBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* background-color: aquamarine; */

}


.editContentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.theContent {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: antiquewhite; */
}


.textEditorBox {
    width: 850px;
    /* height: 700px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}


.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}


.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
}

.aiBtn {
    margin-right: 10px;
    /* float: right; */
}

.textEditorWrapper {
    width: 100%;

    /* width: 100%; */
    /* background-color: red; */
}


.btnBox {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(79, 76, 76);
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    border-bottom: 2px solid rgb(79, 76, 76);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76);
    outline: none;
    min-height: 400px;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}



.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}



.pdfOuterWrapper {
    display: flex;
    justify-content: center;
}

.pdfBox {
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* background-color: #d9e7ff; */
}

.pdfTitleWrapper {
    padding: 10px;
    width: 100%;
    /* background-color: #adbbd4; */
}

.pdfActionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdfTopWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    padding: 10px;
}

.theUploadBox {
    display: flex;
}

.pdfContent {
    width: 100%;
    height: 600px;
    overflow-y: auto;
    background-color: #e0eded;
}



.videoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}

.videoOuterBox {
    display: flex;
    justify-content: center;
}

.videoBox {
    width: 700px;
    /* background-color: #d5d9d9; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.videoContent {
    width: 100%;
    /* background-color: #d9e7ff; */
    /* height: 400px; */
}

.videoTitleWrapper {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}

.videoRenderedIframe {
    height: 395px;
    overflow: hidden;
    position: relative;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;

}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.embedBox{
    display: flex;
    flex-direction: column;
}


</style>