<template>
    <div class="mainWrapper">



        <MainSideBarVue />



        <div class="rightContentWrapper">


            <div class="topNav">
                <TopBarVue />
            </div>


            <div class="innerContainer">


                <div class="theHeaderWrapper">



                    <div class="backIcon">

                        <i @click="groupTalkStore.goBackToViewAllGroupTalks"
                            style="font-size: 25px; font-weight: bold; cursor: pointer;" class="bi bi-arrow-left"></i>

                        <div style="margin-left: 20px;">
                            {{ groupTalkStore.groupTalk ? groupTalkStore.groupTalk.name : '' }}

                            <!-- <span v-if="groupTalkStore.groupTalk" class="badge rounded-pill bg-dark"
                                style="font-size: 13px;">{{
                                    groupTalkStore.groupTalk.team ? groupTalkStore.groupTalk.team.name : '' }}
                            </span> -->
                        </div>

                    </div>


                    <router-link v-if="groupTalkStore.groupTalk" class="button-6" :to="{
                            name: 'CreateAGroupTalkWizard',
                            params: { uuid: groupTalkStore.groupTalk.uuid },
                        }">
                        New Talk
                    </router-link>




                </div>


                <!-- <h2>{{ groupTalkStore.currentGroupTalkUUID }}</h2> -->



                <div class="messageTimelineWrapper" v-if="groupTalkStore.groupTalkPosts">


                    <div v-if="groupTalkStore.groupTalkPosts.length > 0">

                        <div class="mainitem" v-for="(groupTalkPost, index) in groupTalkStore.groupTalkPosts"
                            :key="index">

                            <div class="dotWrapper">

                                <div class="timelineDotBox">

                                    <i class="bi bi-arrow-right-circle"></i>


                                    <i v-for="index in 5" :key="index" class="bi bi-three-dots-vertical dotStyle"></i>

                                </div>

                            </div>


                            <div class="timelineItem">


                                <div class="timelineRightSide">



                                    <div class="itemDetailsBox">


                                        <div class="col1">

                                            <div class="colHeader">

                                                {{ groupTalkPost.post ? groupTalkPost.post.title : "N/A" }}


                                            </div>

                                            <div class="colBody" v-if="groupTalkPost.post">

                                                {{ groupTalkStore.limitText(groupTalkPost.message) }}

                                                <!-- <br>

                                                <span style="font-size: 12px; margin-top: 30px;">Supervisor: Mario Gordon</span> -->

                                                <!-- <p v-if="groupTalkPost.post.type == 'text'" class="text-muted">
                                                    TEXT
                                                </p>
                                                <p v-if="groupTalkPost.post.type == 'pdf'" class="text-muted">
                                                    PDF
                                                </p>
                                                <p v-if="groupTalkPost.post.type == 'video'" class="text-muted">
                                                    VIDEO
                                                </p> -->

                                            </div>



                                        </div>



                                        <div class="col2">

                                            <div class="colHeader">

                                                Created


                                            </div>

                                            <div class="colBody">

                                                {{ groupTalkStore.formatDate(groupTalkPost.created_at) }}

                                            </div>

                                        </div>

                                        <div class="col3">

                                            <div class="colHeader">

                                                Completed


                                            </div>

                                            <div class="colBody">

                                                <p v-if="groupTalkPost.is_acknowledged == 1" class="text-muted">

                                                    {{ groupTalkPost.time_acknowledged }} - {{
                                                    groupTalkPost.date_acknowledged }}
                                                </p>
                                                <p v-else class="text-muted">

                                                    Not yet
                                                </p>


                                            </div>

                                        </div>

                                        
                                        <div class="col3" v-if="groupTalkPost.is_acknowledged == 1">

                                            <div class="colHeader">

                                               
                                                Method

                                            </div>

                                            <div class="colBody">

                                               <div v-if="groupTalkPost.completion_type === 'checkoff'">
                                                Checkoff
                                               </div>

                                               <div v-if="groupTalkPost.completion_type === 'signature'">
                                                Signatures
                                               </div>

                                               <div v-if="groupTalkPost.completion_type === 'signed_sheet'">
                                                Signin Sheet
                                               </div>


                                            </div>

                                        </div>


                                        <div class="col4" v-if="groupTalkPost.is_acknowledged == 1">

                                            <div class="colHeader"> 

                                                Attendees

                                            </div>

                                            <div class="colBody">

                                                <router-link class="button-3" :to="{
                                                    name: 'ViewGroupTalkAttendees',
                                                    params: { uuid: groupTalkPost.uuid },
                                                }">
                                                    View

                                                </router-link>


                                            </div>

                                        </div>

                                        <div class="col4">

                                            <div class="colHeader">

                                                Team

                                            </div>

                                            <div class="colBody">

                                                <div v-if="groupTalkPost.team">

                                                    {{ groupTalkPost.team.name }}

                                                </div>



                                            </div>

                                        </div>


                                        <div class="col4">

                                            <div class="colHeader">

                                                Supervisor

                                            </div>

                                            <div class="colBody">

                                                <div v-if="groupTalkPost.supervisor">

                                                    {{ groupTalkPost.supervisor.name }}

                                                </div>



                                            </div>

                                        </div>


                                        <div class="col6">

                                            <div class="colHeader">

                                                Actions

                                            </div>

                                            <div class="actionsColBody" style="width: 200px;">


                                                <i class="bi bi-clock theEditMessage"
                                                    @click="() => { groupTalkStore.setCurrentGroupTalk(groupTalkPost); groupTalkStore.openEditMessageModal(); }"></i>


                                                <!-- <router-link :to="{
                                                    name: 'ViewAGroupTalkPostContent',
                                                    params: { uuid: groupTalkPost.uuid },
                                                }">

                                                    <i class="bi bi-eye iconStyle"></i>

                                                </router-link> -->



                                                <router-link v-if="groupTalkPost.post"
                                                    :to="{ name: 'EditPostContent', params: { random_string: groupTalkPost.post.random_string } }">
                                                    <i class="bi bi-pencil iconStyle"></i>
                                                </router-link>






                                                <i @click="() => { groupTalkStore.openDeleteGroupTalkPostModal(); groupTalkStore.setCurrentGroupTalkToDelete(groupTalkPost) }"
                                                    class="bi bi-trash iconStyle"></i>



                                            </div>

                                        </div>



                                    </div>


                                </div>


                            </div>

                        </div>

                    </div>

                    <div v-else>
                        No group talks created yet.
                    </div>





                </div>











            </div>









        </div>



        <div v-if="groupTalkStore.showDeleteGroupTalkPostContentModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to delete this Group Talk?</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeDeleteGroupTalkPostModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">




                    <p>
                        <b style="color: red;">NOTE:</b> Deleting <b>{{ groupTalkStore.currentGroupTalkPost.post ?
                            groupTalkStore.currentGroupTalkPost.post.title : '' }}</b> also deletes any content and
                        acknowledgements related to it. You will not be able to recover this data.
                    </p>



                    <div>
                        <button @click="groupTalkStore.deleteThisTalk" type="button" class="btn btn-danger createBTN">
                            <b>Yes, Delete!</b>
                        </button>
                    </div>



                </div>
            </div>
        </div>

        <div v-if="groupTalkStore.showEditMessageModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Edit Talk Schedule</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeEditMessageModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <!-- {{ groupTalkStore.currentGroupTalkPost }} -->


                    <form @submit.prevent="groupTalkStore.updateMessage">


                        <div class="mb-3">
                            <label class="form-label theLabel">Message</label>

                            <textarea v-model="groupTalkStore.currentGroupTalkPost.message" class="form-control"
                                rows="3" required></textarea>
                        </div>

                        <div class="mb-3">
                            <label class="form-label theLabel">Send Date</label>
                            <input v-model="groupTalkStore.currentGroupTalkPost.send_date" type="date"
                                class="form-control" required />
                        </div>

                        <div class="mb-3">
                            <label class="form-label theLabel">Send Time</label>
                            <input v-model="groupTalkStore.currentGroupTalkPost.send_time" type="time"
                                class="form-control" required />
                        </div>

                        <button type="submit" class="btn btn-dark" style="width: 100%;">
                            <b>Save</b>
                        </button>

                    </form>




                </div>
            </div>
        </div>




    </div>
</template>

<script setup>

import { defineProps, onMounted } from 'vue';
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { useGroupTalkStore } from '@/stores/GroupTalkStore';

const groupTalkStore = useGroupTalkStore();



const props = defineProps({
    uuid: String
})



onMounted(() => {

    groupTalkStore.fetchGroupTalk(props.uuid)

    groupTalkStore.currentGroupTalkUUID = props.uuid


})




</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.rightContentWrapper {

    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;




}



.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;

}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}


.topNav {
    background-color: blue;
}


.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
}


.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}


.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}


.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}



.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}


.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}


.messageTimelineWrapper {
    width: 100%;
    /* background-color: rgb(38, 98, 151); */
    margin-top: 50px;
    margin-bottom: 100px;
}



.timelineItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aquamarine;
    width: 100%;
    height: 100%;
}

.timelineDotBox {
    display: flex;
    flex-direction: column;
    width: 5%;
    /* background-color: bisque; */
    /* height: 100px; */
}

.timelineRightSide {
    /* padding: 10px; */
    width: 100%;
    /* background-color: rgb(220, 133, 26); */
}


.itemDetailsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eefff5;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.itemDetailsBoxDraft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eeeeee;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.col1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: 1px black solid; */
}

.col2,
.col3,
.col4,
.col5 {

    width: 50%;
    /* border: 1px black solid; */

}

.colType {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    /* background-color: #94d3a2; */


}

.col6 {

    width: 100%;
    /* border: 1px black solid; */

}


.colHeader {
    font-weight: bold;
    text-align: left;
    padding: 5px;
}

.colBody {
    text-align: left;
    padding: 5px;
    font-size: 14px;
}

.actionsColBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 5px;
}


.dotStyle {
    margin-bottom: -9px;
    color: #e5e3e3;

}


.mainitem {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}


.dotWrapper {
    padding-right: 20px;
}


.theCircle {
    color: black;

}

.form-check-input:checked {
    background-color: #198754;
    /* Bootstrap's green color */
    border-color: #198754;
    /* Bootstrap's green color */
}

.form-check-input:focus {
    border-color: #198754;
    /* Bootstrap's green color */
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    /* A lighter green shadow */
}



.button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.iconStyle {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.iconStyle:hover {
    cursor: pointer;
    scale: 1.2;
    transition: 0.75s;
    color: #198754;
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.modal-content {
    text-align: center;
}


.scheduleTalksHeader {
    text-align: left;
    /* background-color: #94d3a2; */
    width: 100%;
    padding-top: 20px;
    /* font-weight: bold; */
}


.badgy {
    font-size: 11px;
}

.theLabel {
    float: left;
    font-weight: bold;
}


.noTaksBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    /* background-color: #94d3a2; */
    font-size: 15px;
}

.backIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #94d3a2; */
}

.theEditMessage {
    cursor: pointer;
    font-size: 14px;
}
</style>